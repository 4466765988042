/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useRef,
  Suspense,
  lazy,
  ReactNode,
  Fragment,
} from "react";
import { useLocation } from "react-router-dom";
//react bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
//custom components & styles
import PortalCard from "../../components/Card";
import styles from "../global.module.css";
import { Th, Td } from "../../components/Table/HTable";
import { ApplicationsPlaceholder } from "./ApplicationComponents";
import { BannerAlert } from "../../components/Alerts";
//hooks
import useFetchDatastore from "../../hooks/useFetchDatastore";
//lazy loading
const EditApp = lazy(() => import("./EditApply"));
const ViewApp = lazy(() => import("./ViewApplication"));

export const ApplicationHeader = () => (
  <thead>
    <tr>
      <Th className="text-center" children="Fellowship Name" />
      <Th className="text-center" children="Cycle" />
      <Th className="text-center" children="Fellowship Status" />
      <Th className="text-center" children="Application Status" />
      <Th className="text-center" children="Submission Date" />
      <Th className="text-center" children="Action" />
    </tr>
  </thead>
);

interface LocationState {
  key?: string;
  message?: string;
}

interface status {
  message: string;
  rec_deadline: string;
  fellowship_deadline: string;
  closed?: boolean;
}

function getStatus(status: status): ReactNode {
  if (status.message?.includes("incomplete recommendation letter")) {
    const formatted_rec_deadline = new Date(
      status?.rec_deadline
    )?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return (
      <Fragment>
        <p className="text-danger">{status.message}</p>
        <p>
          Letters of recommendation must be submitted by:{" "}
          <span className="text-danger">{formatted_rec_deadline}</span>
        </p>
      </Fragment>
    );
  }
  return <p>{status.message}</p>;
}

export default function ApplicationList() {
  //get url params
  const location = useLocation<LocationState>();
  const prevPathnameRef = useRef(location.pathname);

  //getting states from confirmApply if applicable
  const [appKey, setAppKey] = useState("");

  //states to show modal
  const [showEdit, setShowEdit] = useState(false);
  const [showViewApp, setShowViewApp] = useState(false);

  //success message
  const [successMessage] = useState(
    sessionStorage.getItem("apply_message") || ""
  );

  //reset states if user navigates away from page
  function reset() {
    setAppKey("");
    setShowEdit(false);
    window.history.replaceState({}, document.title);
  }

  //set title of page
  useEffect(() => {
    let title = "My Applications - Fellowships - NYPL";
    if (process.env.REACT_APP_dev) {
      title = process.env.REACT_APP_baseTitle + " | " + title;
    }
    document.title = title;
    //focus on heading
    document.getElementById("heading")?.focus();
    return () => {
      sessionStorage.clear();
    };
  }, []);

  useEffect(() => {
    //if the user navigates away from the page, reset the states
    if (prevPathnameRef.current !== location.pathname) reset();
    //set the previous pathname to the current pathname
    prevPathnameRef.current = location.pathname;
    if (!!location.state?.message && !!location.state?.key) setShowEdit(true);
  }, [location.pathname]);

  //fetch data from datastore
  const { data, isPending, error } = useFetchDatastore(
    process.env.REACT_APP_baseURL + "api/user-applications/"
  );

  //state for data
  const [applications, setApplications] = useState<any[]>(data || []);
  useEffect(() => {
    setApplications(data);
    //set app key to corresponding application via fellowship urlsafekey
    if (Array.isArray(data) && data.length > 0) {
      setAppKey(
        data.find(
          (app: any) => app.fellowship_urlsafekey === location.state?.key
        )?.urlsafekey || ""
      );
    }
  }, [data]);

  const noData = applications?.length === 0 ? true : false;

  return (
    <Container>
      {/* Error Message */}
      {error && (
        <Row className="mt-2">
          <BannerAlert title="Error" message={error} variant="danger" />
        </Row>
      )}
      {/* Success Message */}
      {!!successMessage && (
        <Row className="mt-2">
          <BannerAlert
            message={successMessage}
            variant="success"
            removeAlert={() => sessionStorage.removeItem("apply_message")}
          />
        </Row>
      )}
      <Row>
        <h1
          tabIndex={-1}
          id="heading"
          className={`display-4 ${styles.page_header}`}
        >
          My Application(s)
        </h1>
      </Row>
      <Row>
        <PortalCard>
          {/* If theres data */}
          {!noData && !isPending ? (
            <Suspense fallback={<ApplicationsPlaceholder />}>
              <Table hover responsive>
                {/* Edit app modal */}
                <EditApp
                  application_key={appKey}
                  show={showEdit}
                  onHide={() => setShowEdit(false)}
                  onSuccess={() => {
                    const updated_url = sessionStorage.getItem("success_apply");
                    sessionStorage.clear();
                    if (updated_url) {
                      const updated_app = applications?.find(
                        (app: any) => app.urlsafekey === updated_url
                      );
                      if (updated_app) {
                        let message = "";
                        switch (updated_app.status.message) {
                          case "In Progress":
                            message = `Application successfully submitted for ${updated_app.fellowship_name}`;
                            break;
                          default:
                            message = `Application successfully updated for ${updated_app.fellowship_name}`;
                            break;
                        }
                        reset();
                        sessionStorage.setItem("apply_message", message);
                        window.location.reload();
                      }
                    }
                  }}
                />
                {/* confirm close modla for edit */}
                {/* View app modal */}
                <ViewApp
                  application_key={appKey}
                  show={showViewApp}
                  onHide={() => {
                    reset();
                    setShowViewApp(false);
                  }}
                />
                <ApplicationHeader />
                <tbody>
                  {applications?.map((application: any) => {
                    const formattedDate = new Date(
                      application.modified
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    });

                    const formatted_fellowship_deadline = new Date(
                      application.status?.fellowship_deadline || ""
                    )?.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    });

                    const year = new Date(formattedDate).getFullYear();
                    return (
                      <tr key={application.urlsafekey}>
                        {/* Fellowship Name */}
                        <Td children={<p>{application.fellowship_name}</p>} />
                        {/* Cycle */}
                        <Td children={year} />
                        {/* Fellowship Status */}
                        <Td
                          children={
                            <p>
                              {application?.is_fellowship_open ? (
                                <span className="text-success">Open</span>
                              ) : (
                                <span className="text-danger">
                                  Closed on {formatted_fellowship_deadline}
                                </span>
                              )}
                            </p>
                          }
                        />
                        {/* Application Status */}
                        <Td children={getStatus(application.status)} />
                        {/* Submission Date */}
                        <Td
                          first
                          children={
                            application?.is_app_completed ? (
                              formattedDate
                            ) : (
                              <p className="text-danger">Not Submitted</p>
                            )
                          }
                        />
                        {/* Action */}
                        <Th className="text-center">
                          <ButtonGroup>
                            {application?.is_app_completed && (
                              <Button
                                variant="outline-dark"
                                size="sm"
                                onClick={() => {
                                  setAppKey(application.urlsafekey);
                                  setShowViewApp(true);
                                }}
                                aria-label="View Completed Application"
                              >
                                View
                              </Button>
                            )}

                            <Button
                              id={`edit_${application.urlsafekey}`}
                              variant="outline-nypl-primary"
                              size="sm"
                              onClick={() => {
                                setAppKey(application.urlsafekey);
                                setShowEdit(true);
                              }}
                            >
                              {application?.is_app_completed ? "Edit" : "Continue"}
                            </Button>
                          </ButtonGroup>
                        </Th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Suspense>
          ) : // If theres no data
          noData && !isPending ? (
            <Row className="text-center my-4">
              <p className="display-6">No Applications Found</p>
            </Row>
          ) : // If data is pending
          isPending ? (
            <ApplicationsPlaceholder />
          ) : null}
        </PortalCard>
      </Row>
    </Container>
  );
}
